import {AnimateItem, AnimateItemType} from '@PosterWhiteboard/animation/animate-item.class';

export class IntroAnimateItem extends AnimateItem {
  public type = AnimateItemType.INTRO_ANIMATE_ITEM;
  public initAnimation(): void {
    this.onStartValues = this.getOnStartValues();
    this.startingAnimationValues = this.getAnimationValues();
    this.endingAnimationValues = this.defaultValues;
    this.setAnimatingItemValues(this.onStartValues);
    this.setAnimatingItemValues(this.startingAnimationValues);
  }

  public blockAnimationOnChange(): void {
    this.animatingItem.setCoords();

    if (this.shouldBlockBeMadeVisible() && this.fabricObject.opacity !== this.viewComponentOpacitySnapshot) {
      this.fabricObject.set('opacity', this.viewComponentOpacitySnapshot);
    }
  }

  public restoreOpacityOnComplete(): void {
    this.fabricObject.set('opacity', this.viewComponentOpacitySnapshot);
  }

  public hideItemOnBlockInit(): void {
    this.fabricObject.set('opacity', 0);
  }
}
