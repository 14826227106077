import {CommonMethods} from '@PosterWhiteboard/common-methods';
import type {TextItem} from '@PosterWhiteboard/items/text-item/text-item.class';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import {FillTypes, Fill} from '@PosterWhiteboard/classes/fill.class';
import type {DeepPartial} from '@/global';

export interface TextBackgroundObject {
  fill: FillObject;
  rx: number;
  ry: number;
}

export class TextBackground extends CommonMethods {
  public item: TextItem;
  public fill: Fill;
  public rx = 0;
  public ry = 0;

  constructor(item: TextItem) {
    super();
    this.item = item;
    this.fill = new Fill();
    this.fill.fillType = FillTypes.NONE;
    this.fill.fillColor = [[185, 185, 185, 1]];
  }

  public copyVals(obj: DeepPartial<TextBackgroundObject>): void {
    const {fill, ...copyObj} = obj;
    super.copyVals(copyObj);
    this.fill.copyVals(fill);
  }

  public toObject(): TextBackgroundObject {
    return {
      fill: this.fill.toObject(),
      rx: this.rx,
      ry: this.ry,
    };
  }
}
