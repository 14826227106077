import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {BaseItemObject} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import type {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {RGBA} from '@Utils/color.util';
import {LinePointsStyle, LineDash} from '@PosterWhiteboard/items/line-item/pmw-fabric-line';
import type {CustomControl, OnResizeParams} from '@PosterWhiteboard/poster/poster-item-controls';
import {renderControl} from '@PosterWhiteboard/poster/poster-item-controls';
import {Control, controlsUtils, Polyline, type TPointerEvent, type Transform} from '@postermywall/fabricjs-2';
import type {
  CopyableItemStylesAndProperties,
  LineItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForLineItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export interface LineItemObject extends BaseItemObject {
  fill: FillObject;
  lineDash: LineDash;
  lineEnd: LinePointsStyle;
  lineStart: LinePointsStyle;
}

export class LineItem extends Item {
  declare fabricObject: Polyline;
  public gitype: ITEM_TYPE.LINE = ITEM_TYPE.LINE;
  public fill: Fill;
  public thickness = 4;
  public lineDash: LineDash = LineDash.NONE;
  public lineEnd: LinePointsStyle = LinePointsStyle.NONE;
  public lineStart: LinePointsStyle = LinePointsStyle.NONE;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill();
  }

  public async isPDFSafe(): Promise<boolean> {
    return true;
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      fill: this.fill.toObject(),
      lineDash: this.lineDash,
      lineEnd: this.lineEnd,
      lineStart: this.lineStart,
    } as LineItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForLineItem(copiedProperties, this);
  }

  public hasClickableLink(): boolean {
    return false;
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    const fillColor = this.fill.fillColor.slice();
    for (const item of fillColor) {
      item[3] = fillAlpha;
    }

    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public toObject(): LineItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      lineDash: this.lineDash,
      lineEnd: this.lineEnd,
      lineStart: this.lineStart,
    };
  }

  private getLineMLControl(onResize: (params: OnResizeParams) => void): CustomControl {
    const key = 'lineMl';
    return {
      key,
      control: new Control({
        x: -0.5,
        y: 0,
        visible: false,
        cursorStyleHandler: (): string => {
          return 'crosshair';
        },
        actionName: 'resize',
        render: renderControl.bind(this, key),
        actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
          if (!transformData.corner) {
            return false;
          }

          onResize({e, delta: 5});
          return true;
        },
      }),
    };
  }

  private getLineMrControl(onResize: (params: OnResizeParams) => void): CustomControl {
    const key = 'lineMr';
    return {
      key,
      control: new Control({
        x: 0.5,
        y: 0,
        visible: false,
        cursorStyleHandler: (): string => {
          return 'crosshair';
        },
        actionName: 'resize',
        render: renderControl.bind(this, key),
        actionHandler: (e: TPointerEvent, transformData: Transform): boolean => {
          if (!transformData.corner) {
            return false;
          }

          onResize({e, delta: 5});
          return true;
        },
      }),
    };
  }

  public updateFillColor(fillColor: RGBA[], undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  protected async getFabricObjectForItem(): Promise<Polyline> {
    const obj = new Polyline(
      [
        {x: 3, y: 30},
        {x: 300, y: 30},
      ],
      {
        hasBorders: true,
        strokeWidth: 1,
        stroke: 'grey',
      }
    );
    obj.set({
      controls: controlsUtils.createPolyControls(obj),
    });
    obj.set(this.getCommonOptions());
    return obj;
  }

  public async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
      thickness: this.thickness,
      strokeWidth: this.thickness,
      stroke: 'grey',
      lineDash: this.lineDash,
      lineEnd: this.lineEnd,
      lineStart: this.lineStart,
    });
  }
}
