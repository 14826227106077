import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {RGBA} from '@Utils/color.util';
import {rgbaToHexString} from '@Utils/color.util';
import {type InitItemOpts, ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {cloneAsImageWithAngleIgnored, getPaths} from '@Utils/fabric.util';
import {degToRad} from '@Utils/math.util';
import type {VectorItemObject} from '@PosterWhiteboard/items/vector-item/vector-item.types';
import type {VectorItemSource} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import {arePathsCustomizable, loadVectorSVG} from '@PosterWhiteboard/items/vector-item/vector-item.library';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import type {FabricObject, GradientOptions} from '@postermywall/fabricjs-2';
import {util, Path, Group, Point} from '@postermywall/fabricjs-2';
import type {DeepPartial} from '@/global';
import type {
  CopyableItemStylesAndProperties,
  VectorItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForVectorItem} from '@PosterWhiteboard/libraries/paste-styles.library';

const MAX_VECTOR_PATHS = 200;
const MAX_SINGLE_PATH_OUTLINE_THICKNESS = 100;
const MAX_MULTI_PATH_OUTLINE_THICKNESS = 40;

interface VectorPositionFixVals {
  a: number;
  b: number;
  c: number;
  d: number;
}

enum ShapeVersions {
  /**
   * Version of shape when uniform stroke was fixed for user shapes, and we changed the single path check.
   */
  VERSION_3 = 3,
  /**
   * Version of shape when uniform stroke was changed to use fabric uniformStroke flag instead of manually changing it for multipath vectors
   */
  VERSION_4 = 4,
  /**
   * Version of shape when opacity was changed to be applied to the whole shape and not only fill
   */
  VERSION_5 = 5,
}

export class VectorItem extends Item {
  declare fabricObject: Group | Path;

  public gitype = ITEM_TYPE.VECTOR;
  public source!: VectorItemSource;
  public fileName = '';
  public fill: Fill;
  declare version: ShapeVersions;

  public isCustomisable = true;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill({
      getRadialGradientOpts: getRadialGradientOptsForVectorItem,
    });
  }

  public override toObject(): VectorItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      fileName: this.fileName,
      source: this.source,
      isCustomisable: this.isCustomisable,
    };
  }

  protected override async onItemInitialized(): Promise<void> {
    if (this.hasTooManyPaths(this.fabricObject)) {
      await this.convertVectorToImage();
    }
    await this.updateFabricObject();
  }

  protected override isVisible(): boolean {
    const isVisible = super.isVisible();
    return isVisible && ((this.fill.hasFill() && this.fabricObject.opacity > 0) || this.border.solidBorderThickness > 0);
  }

  protected override async getFabricObjectForItem(opts?: InitItemOpts): Promise<FabricObject> {
    const {paths} = await loadVectorSVG(this.fileName, this.source);
    const fabricOpts = {
      ...this.getInitOptionsForView(),
    };
    this.isCustomisable = arePathsCustomizable(paths);
    this.preparePaths(paths);

    const svg = util.groupSVGElements(paths, fabricOpts);
    svg.set({
      uniformScaling: !(this.fileName === '803' || this.fileName === '804'),
      ...this.getScalesToMaintainScaledDimensions(svg.width, svg.height, opts),
    });
    return svg;
  }

  protected hasSinglePath(): boolean {
    if (this.version >= ShapeVersions.VERSION_3) {
      return !(this.fabricObject instanceof Group);
    }

    // Old way of checking if a vector is single path or not
    return this.fabricObject instanceof Path;
  }

  protected getMaxOutlineThickness(): number {
    return this.hasSinglePath() ? MAX_SINGLE_PATH_OUTLINE_THICKNESS : MAX_MULTI_PATH_OUTLINE_THICKNESS;
  }

  public override copyVals(obj: DeepPartial<VectorItemObject>): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public override async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.setFill();
    this.applyFixForVersion4();
    await this.applyFixForVersion5();
  }

  public override applyBorder(): void {
    if (this.isCustomisable) {
      const paths = getPaths(this.fabricObject);
      const strokeHex = rgbaToHexString(this.border.solidBorderColor);

      for (const item of paths) {
        item.set({
          stroke: strokeHex,
        });
      }

      this.fabricObject.set({
        ...this.border.getBorder(),
      });
    }
  }

  public override getColors(): RGBA[] {
    let colors: RGBA[] = super.getColors();

    if (this.fill.hasFill()) {
      colors = [...colors, ...this.fill.fillColor];
    }

    colors.push(this.border.solidBorderColor);

    return colors;
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      fill: this.fill.toObject(),
    } as VectorItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForVectorItem(copiedProperties, this);
  }

  protected override itemObjectHasDestructiveChanges(oldItemObject: VectorItemObject): boolean {
    if (!this.fabricObject) {
      return false;
    }

    if (this.isCustomisable && !this.hasSinglePath()) {
      if (oldItemObject.border.solidBorderThickness !== this.border.solidBorderThickness) {
        return true;
      }
    }
    return false;
  }

  protected override getInitOptionsForView(): Record<string, unknown> {
    return {
      perPixelTargetFind: true,
      selectable: true,
      lockUniScaling: false,
    };
  }

  private preparePaths(paths: FabricObject[]): void {
    if (this.isCustomisable) {
      for (const path of paths) {
        path.set({
          strokeWidth: this.border.solidBorderThickness,
          strokeUniform: true,
        });
      }
    }
  }

  private applyFixForVersion4(): void {
    if (!this.isMultipathVectorUniformStrokeFixed() && !this.hasSinglePath()) {
      // Set the new x/y to item. Apply strokeweight offset as that is now included in fabric object calculations too
      const coords = this.fabricObject.calcACoords();
      const cosOfAngle = Math.cos(degToRad(this.fabricObject.angle));
      const sinOfAngle = Math.sin(degToRad(this.fabricObject.angle));
      const cosVal = (cosOfAngle * this.border.solidBorderThickness) / 2;
      const sinVal = (sinOfAngle * this.border.solidBorderThickness) / 2;

      // I honestly don't know why I had to use that combination of cos and sin. Just came up with it by hit and trial
      this.fabricObject.setXY(new Point(coords.tl.x + cosVal - sinVal, coords.tl.y + cosVal + sinVal));

      this.fabricObject.set({
        originX: 'left',
        originY: 'top',
      });

      this.version = ShapeVersions.VERSION_4;
    }
  }

  private isMultipathVectorUniformStrokeFixed(): boolean {
    return this.version >= ShapeVersions.VERSION_4;
  }

  private doesOpacityWorkOnStroke(): boolean {
    return this.version >= ShapeVersions.VERSION_5;
  }

  private async applyFixForVersion5(): Promise<void> {
    if (!this.doesOpacityWorkOnStroke()) {
      if (this.isCustomisable) {
        const fillColor = this.fill.fillColor.slice();
        for (const item of fillColor) {
          item[3] = this.fabricObject.opacity;
        }

        this.fill.fillColor = fillColor;
      }
      this.fabricObject.opacity = 1;
      this.version = ShapeVersions.VERSION_5;
      await this.updateFabricObject();
    }
  }

  private hasTooManyPaths(svg: FabricObject): boolean {
    return getPaths(svg).length > MAX_VECTOR_PATHS;
  }

  public hasMultiplePaths(): boolean {
    return getPaths(this.fabricObject).length > 1;
  }

  protected override getOldShadowDistance(): number {
    return 2;
  }

  protected override fixChanges(applyVersionFixesData: Record<string, any>): void {
    super.fixChanges(applyVersionFixesData);
    if (this.page.poster.version < POSTER_VERSION.FABRIC_2_UPDATE) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * Math.max(this.fabricObject.scaleX, this.fabricObject.scaleY));
    }

    if (this.page.poster.version < POSTER_VERSION.FABRIC_3_UPDATE) {
      switch (this.fileName) {
        case '38':
        case '41':
        case '28':
        case '34':
        case '35':
        case '30':
        case '50':
        case '10':
        case '9':
          this.applyVectorPositionFix({
            a: -0.013468013468013,
            b: -0.23878787878788,
            c: 1.9692929292929,
            d: -0.32727272727273,
          });
          break;

        case '12':
        case '11':
        case '24':
        case '7':
        case '8':
          this.applyVectorPositionFix({
            a: -0.019418426691154,
            b: -0.099467401285583,
            c: 1.0419467401286,
            d: -1.1844628099174,
          });
          return;

        case '33':
        case '32':
        case '36':
        case '20':
        case '6':
          this.applyVectorPositionFix({
            a: -0.1724436282012,
            b: -0.058475665748393,
            c: 3.4995041322314,
            d: 1.1639669421488,
          });
          break;

        default:
          break;
      }
    }
  }

  private applyVectorPositionFix(offsets: VectorPositionFixVals): void {
    // equation is = (a*scale + b) * stroke  + c * scale + d
    const xCoorDelta = Math.round((offsets.a * this.fabricObject.scaleX + offsets.b) * this.border.solidBorderThickness + offsets.c * this.fabricObject.scaleX + offsets.d);
    const yCoorDelta = Math.round((offsets.a * this.fabricObject.scaleY + offsets.b) * this.border.solidBorderThickness + offsets.c * this.fabricObject.scaleY + offsets.d);
    let xcor1 = -yCoorDelta * Math.sin((this.fabricObject.angle * Math.PI) / 180);
    let ycor1 = yCoorDelta * Math.cos((this.fabricObject.angle * Math.PI) / 180);

    xcor1 += xCoorDelta * Math.cos((this.fabricObject.angle * Math.PI) / 180);
    ycor1 += xCoorDelta * Math.sin((this.fabricObject.angle * Math.PI) / 180);

    this.fabricObject.setXY(new Point(this.fabricObject.getX() + xcor1, this.fabricObject.getY() + ycor1));
  }

  private setFill(): void {
    if (this.isCustomisable) {
      const paths = getPaths(this.fabricObject);

      for (const item of paths) {
        item.set({
          fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
        });
      }
    }
  }

  private async convertVectorToImage(): Promise<void> {
    this.setFill();
    this.applyBorder();
    const img = await cloneAsImageWithAngleIgnored(this.fabricObject, {
      multiplier: this.page.poster.scaling.scale,
    });
    if (!img.width || !img.height) {
      throw new Error('Failed to get image for vector');
    }

    img.set({
      scaleX: this.fabricObject.scaleX * (this.fabricObject.get('width') / img.width),
      scaleY: this.fabricObject.scaleY * (this.fabricObject.get('height') / img.height),
    });
    this.setFabricObject(img);
    this.isCustomisable = false;
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    const fillColor = this.fill.toObject().fillColor.slice();
    for (let i = 0; i < fillColor.length; i++) {
      const color = fillColor[i].slice();
      color[3] = fillAlpha;
      fillColor[i] = color as RGBA;
    }

    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillColor(fillColor: RGBA[], undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }
}

const getRadialGradientOptsForVectorItem = (fillWidth: number, fillHeight: number, gradientFillColors: RGBA[]): GradientOptions<'radial'> => {
  const x = fillWidth / 2;
  const y = fillHeight / 2;
  const maxDimension = Math.max(x, y);

  return {
    colorStops: [
      {offset: 0, color: rgbaToHexString(gradientFillColors[0])},
      {offset: 0.75, color: rgbaToHexString(gradientFillColors[1])}, // to cater for most SVGs having space between the control box and shape
    ],
    type: 'radial',
    coords: {
      r1: 0.1 * maxDimension * 2,
      r2: maxDimension * 2,
      x1: maxDimension,
      y1: maxDimension,
      x2: maxDimension,
      y2: maxDimension,
    },
  };
};
