import {AnimateItem, AnimateItemType} from '@PosterWhiteboard/animation/animate-item.class';

export class OutroAnimateItem extends AnimateItem {
  public type = AnimateItemType.OUTRO_ANIMATE_ITEM;
  public initAnimation(): void {
    this.onStartValues = this.getOnStartValues();
    this.endingAnimationValues = this.getAnimationValues();
    this.startingAnimationValues = this.defaultValues;

    this.setAnimatingItemValues(this.onStartValues);
  }

  public blockAnimationOnChange(): void {
    this.animatingItem.setCoords();

    if (this.shouldBlockBeMadeVisible() && this.fabricObject.opacity !== 0) {
      this.fabricObject.set('opacity', 0);
    }
  }

  public hideItemOnComplete(): void {
    this.fabricObject.set('opacity', 0);
  }
}
