import {IntroAnimateItem} from '@PosterWhiteboard/animation/intro-animate-item.class';
import {round} from 'lodash';
import {NUM_FRACTION_DIGITS} from '@PosterWhiteboard/common.types';
import {isWipeOrBlockAnimation} from '@PosterWhiteboard/animation/animation.class';
import type {SlideItem} from '@PosterWhiteboard/items/slideshow-item/slideshow-item.types';

interface PositionAndScale {
  x: number;
  y: number;
  scaleX: number;
  scaleY: number;
}

export function getStaticSlideValues(slide: SlideItem): PositionAndScale {
  let scaleX = slide.getScaleX();
  let scaleY = slide.getScaleY();
  let x = slide.getX();
  let y = slide.getY();

  const animation = slide.slideshow.animation;
  // in case of block & wipe, startingAnimationValues/endingAnimationValues are applied to block & clipPath and not slide's fabric object (opacity is applied to fabricObject in block though)
  // opacity isn't being used here, but if it were, it should get it from viewComponentOpacitySnapshot for block animation
  if (
    slide.slideshow.isSlideshowAnimationPlaying &&
    animation &&
    !isWipeOrBlockAnimation(animation.animationType) &&
    slide.slideshow.animation?.fabricObject === slide.fabricObject
  ) {
    if (slide.slideshow.animation instanceof IntroAnimateItem) {
      if ('scaleX' in slide.slideshow.animation.onCompleteValues) {
        scaleX = slide.slideshow.animation.onCompleteValues.scaleX as number;
      }
      if ('scaleY' in slide.slideshow.animation.onCompleteValues) {
        scaleY = slide.slideshow.animation.onCompleteValues.scaleY as number;
      }
      if ('left' in slide.slideshow.animation.onCompleteValues) {
        x = slide.slideshow.animation.onCompleteValues.left as number;
      }
      if ('top' in slide.slideshow.animation.onCompleteValues) {
        y = slide.slideshow.animation.onCompleteValues.top as number;
      }
    } else {
      if ('scaleX' in slide.slideshow.animation.startingAnimationValues) {
        scaleX = slide.slideshow.animation.startingAnimationValues.scaleX as number;
      }
      if ('scaleY' in slide.slideshow.animation.startingAnimationValues) {
        scaleY = slide.slideshow.animation.startingAnimationValues.scaleY as number;
      }
      if ('left' in slide.slideshow.animation.startingAnimationValues) {
        x = slide.slideshow.animation.startingAnimationValues.left as number;
      }
      if ('top' in slide.slideshow.animation.startingAnimationValues) {
        y = slide.slideshow.animation.startingAnimationValues.top as number;
      }
    }
  }

  return {
    x: round(x, NUM_FRACTION_DIGITS),
    y: round(y, NUM_FRACTION_DIGITS),
    scaleX: round(scaleX, NUM_FRACTION_DIGITS),
    scaleY: round(scaleY, NUM_FRACTION_DIGITS),
  };
}
