import type {BaseItemObject, InitItemOpts} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {Page} from '@PosterWhiteboard/page/page.class';
import {openPosterEditorItemEffectsModal} from '@Modals/poster-editor-item-effects-modal';
import {Item} from '@PosterWhiteboard/items/item/item.class';
import type {ItemEffectsObject} from '@PosterWhiteboard/classes/item-effects.class';
import {ItemEffects} from '@PosterWhiteboard/classes/item-effects.class';
import {SyncToPosterClock} from '@PosterWhiteboard/classes/sync-to-poster-clock.class';
import type {AnimatedSprite} from '@Libraries/animated-sprite.library';
import {getFramesForSprite} from '@Libraries/animated-sprite.library';
import {Sprite} from '@PosterWhiteboard/items/fabric-sprite';
import type {DeepPartial} from '@/global';
import type {
  CopyableItemStylesAndProperties,
  StickerItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForStickerItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export interface StickerItemObject extends BaseItemObject {
  hashedFilename: string;
  duration: number;
  frameRate: number;
  effects: ItemEffectsObject;
  highResAnimatedSprite: AnimatedSprite;
  screenAnimatedSprite: AnimatedSprite;
}

/**
 * The version at which we enabled uniform stroke for motion items.
 */
const VERSION_2 = 2;

/**
 * Default min duration for which stickers should be played
 */
const STICKER_MIN_PLAY_DURATION = 10;

export class StickerItem extends Item {
  declare fabricObject: Sprite;
  public gitype: ITEM_TYPE.STICKER = ITEM_TYPE.STICKER;
  public frameRate = 0;
  public hashedFilename = '';
  public duration = 0;
  public effects: ItemEffects;
  public override version = 2;
  public htmlImageElement!: HTMLImageElement;
  public syncToPosterClock: SyncToPosterClock;
  public highResAnimatedSprite!: AnimatedSprite;
  public screenAnimatedSprite!: AnimatedSprite;
  /**
   * Sync to poster click function is cached in this variable so that it can be unmounted on item remove. Inline use of that
   * function doesn't work because of bind (https://stackoverflow.com/questions/28800850/jquery-off-is-not-unbinding-events-when-using-bind)
   */
  private readonly syncToPosterClockFunction;

  public constructor(page: Page) {
    super(page);
    this.effects = new ItemEffects(this);
    this.syncToPosterClock = new SyncToPosterClock({
      getDuration: this.getDuration.bind(this),
      getCurrentTime: this.getCurrentTime.bind(this),
      seek: this.seek.bind(this),
      pauseAtEnd: false,
    });
    this.syncToPosterClockFunction = this.syncToPosterClock.syncToPage.bind(this.syncToPosterClock);
  }

  public override getPlayDuration(): number {
    return Math.max(this.getDuration(), STICKER_MIN_PLAY_DURATION);
  }

  public async getFabricObjectForItem(opts?: InitItemOpts): Promise<Sprite> {
    const currentSprite = this.getCurrentSprite();

    const frameImages = await getFramesForSprite(currentSprite);
    if (!frameImages[0]) {
      throw new Error(`No frame images for sprite: ${JSON.stringify(currentSprite)}`);
    }
    [this.htmlImageElement] = frameImages;

    return new Sprite(this.htmlImageElement, {
      ...super.getCommonOptions(),
      frameImages,
      frameHeight: this.htmlImageElement.height,
      frameWidth: currentSprite.frameWidth,
      frameTime: 1000 / currentSprite.frameRate,
      ...this.getScalesToMaintainScaledDimensions(this.htmlImageElement.width, this.htmlImageElement.height, opts),
    });
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      effects: this.effects.toObject(),
    } as StickerItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForStickerItem(copiedProperties, this);
  }

  public override getDuration(): number {
    return this.duration;
  }

  public getCurrentTime(): number {
    return this.fabricObject.getCurrentTime();
  }

  public override seek(time: number): Promise<void> {
    return new Promise((resolve) => {
      const seekTime = time % this.getDuration();
      this.fabricObject.seek(seekTime);
      resolve();
    });
  }

  public override stop(): Promise<void> {
    return new Promise((resolve) => {
      this.fabricObject.stop();
      resolve();
    });
  }

  public override play(): Promise<void> {
    return new Promise((resolve) => {
      this.fabricObject.play();
      resolve();
    });
  }

  public override pause(): Promise<void> {
    return new Promise((resolve) => {
      this.fabricObject.pause();
      resolve();
    });
  }

  public override isStreamingMediaItem(): boolean {
    return true;
  }

  public override copyVals(obj: DeepPartial<StickerItemObject>): void {
    const {effects, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.effects.copyVals(effects);
  }

  public override async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.effects.applyItemEffects();
    await this.effects.applyBorderEffects();
  }

  protected override fixChanges(applyVersionFixesData: Record<string, any>): void {
    super.fixChanges(applyVersionFixesData);
    this.applyFixForVersion2();
  }

  private applyFixForVersion2(): void {
    if (!this.hasUniformStroke()) {
      this.border.solidBorderThickness = Math.round(this.border.solidBorderThickness * this.fabricObject.scaleX);
      this.version = VERSION_2;
    }
  }

  public override onRemove(): void {
    super.onRemove();
    this.fabricObject.onRemove();
    this.page.poster.off('time:updated', this.syncToPosterClockFunction);
  }

  public override async onItemAddedToPage(): Promise<void> {
    this.page.poster.on('time:updated', this.syncToPosterClockFunction);

    if (this.page.background.isTransparentBackground()) {
      this.page.background.updateBackgroundToBeNonTransparent();
    }
    if (this.page.poster.isPlaying()) {
      await this.play();
    }
  }

  public hasUniformStroke(): boolean {
    return this.version >= VERSION_2;
  }

  private getCurrentSprite(): AnimatedSprite {
    if (this.page.poster.isHighRes) {
      return this.highResAnimatedSprite;
    }

    return this.screenAnimatedSprite;
  }

  public override toObject(): StickerItemObject {
    return {
      ...super.toObject(),
      effects: this.effects.toObject(),
      hashedFilename: this.hashedFilename,
      duration: this.duration,
      frameRate: this.frameRate,
      highResAnimatedSprite: this.highResAnimatedSprite,
      screenAnimatedSprite: this.screenAnimatedSprite,
    };
  }

  protected override onItemDoubleTapped(): void {
    openPosterEditorItemEffectsModal();
  }
}
