import type {MotionItemObject} from '@PosterWhiteboard/items/motion-item.class';
import {DEFAULT_VIDEO_EXTENSION, MotionItem} from '@PosterWhiteboard/items/motion-item.class';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import {POSTER_VERSION} from '@PosterWhiteboard/poster/poster.types';
import {mapNumberToRange} from '@Utils/math.util';
import {RemoveVideoItemBackground} from '@PosterWhiteboard/items/video-item/remove-video-item-background.class';
import type {RemoveVideoItemBackgroundObject} from '@PosterWhiteboard/items/video-item/remove-video-item-background.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {RemoveVideoBackgroundTrimData} from '@Utils/video.util';
import type {ImageItemObject} from '@PosterWhiteboard/items/image-item/image-item.class';
import type {FabricImage} from '@postermywall/fabricjs-2';
import {repoVideoURLForModel, shouldLoadGreenScreenMp4, USER_VIDEO_SIZE_TYPE, USER_VIDEO_SOURCE} from '@/libraries/user-video-library';
import type {DeepPartial} from '@/global';
import type {
  CopyableItemStylesAndProperties,
  VideoItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForVideoItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export interface VideoItemObject extends MotionItemObject {
  videoSource: USER_VIDEO_SOURCE;
  fileExtension: string;
  removeBackground: RemoveVideoItemBackgroundObject;
}

export class VideoItem extends MotionItem {
  declare fabricObject: FabricImage;
  declare htmlElement: HTMLVideoElement;
  public gitype: ITEM_TYPE = ITEM_TYPE.VIDEO;
  public fileExtension = DEFAULT_VIDEO_EXTENSION;
  public videoSource!: USER_VIDEO_SOURCE;
  public removeBackground: RemoveVideoItemBackground;

  public constructor(page: Page) {
    super(page);
    this.removeBackground = new RemoveVideoItemBackground(this);
  }

  protected override async loadVideoElement(): Promise<void> {
    await this.removeBackground.ensureRemovedBackgroundVideoExistsIfNeeded();
    await super.loadVideoElement();
  }

  public hasGettyContent(): boolean {
    return this.videoSource === USER_VIDEO_SOURCE.GETTY;
  }

  public override isPremium(): boolean {
    return this.hasGettyContent();
  }

  protected override itemObjectHasDestructiveChanges(oldItemObject: VideoItemObject): boolean {
    return this.hasRemoveBackgroundChanged(oldItemObject);
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      effects: this.effects.toObject(),
    } as VideoItemStyles;
  }
  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForVideoItem(copiedProperties, this);
  }

  private hasRemoveBackgroundChanged(oldValues: VideoItemObject): boolean {
    return this.removeBackground.isBackgroundRemoved !== oldValues.removeBackground.isBackgroundRemoved;
  }

  public override toObject(): VideoItemObject {
    return {
      ...super.toObject(),
      fileExtension: this.fileExtension,
      videoSource: this.videoSource,
      removeBackground: this.removeBackground.toObject(),
    };
  }

  public override copyVals(obj: DeepPartial<ImageItemObject>): void {
    const {removeBackground, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.removeBackground.copyVals(removeBackground);
  }

  protected getVideoUrl(): string {
    if (this.page.poster.mode.isWebpage()) {
      return repoVideoURLForModel(this, USER_VIDEO_SIZE_TYPE.SCREEN);
    }
    return repoVideoURLForModel(this);
  }

  protected override fixChanges(applyVersionFixesData: Record<string, any>): void {
    super.fixChanges(applyVersionFixesData);
    this.fixBrightness();
  }

  public toggleMuteUnmuteState(): void {
    void this.updateFromObject({
      isMuted: !this.isMuted,
    });
  }

  protected override isGreenScreenMp4Loaded(): boolean {
    return shouldLoadGreenScreenMp4(this.fileExtension, this.isItemTransparent());
  }

  public isItemTransparent(): boolean {
    return this.removeBackground.isBackgroundRemoved || this.hasTransparency;
  }

  public getStartTimeForNonRemovedBgVideo(): number {
    if (this.removeBackground.isBackgroundRemoved) {
      return this.startTime + (this.removeBackground.trimData.startTime ?? 0);
    }
    return this.startTime;
  }

  public getEndTimeForNonRemovedBgVideo(): number {
    if (this.removeBackground.isBackgroundRemoved) {
      return this.endTime + (this.removeBackground.trimData.startTime ?? 0);
    }
    return this.endTime;
  }

  /**
   * Brightness used to have a range of -100 to 100 in HTML5 posterbuilder build using fabricJs (Version: 1.5),
   * After upgrading to fabricJs (version: 2.0), new range for brightness filter is -0.4 to 0.4 hence with the upgraded
   * posterBuilder we need to map the old brightness value of images.
   */
  private fixBrightness(): void {
    if (this.page.poster.version < POSTER_VERSION.FABRIC_2_UPDATE) {
      this.effects.brightness = mapNumberToRange(this.effects.brightness, -100, 100, -0.4, 0.4);
    }
  }

  public async enableRemoveBackgroundFlag(trimData: RemoveVideoBackgroundTrimData): Promise<void> {
    await this.updateFromObject({
      startTime: 0,
      endTime: trimData.trimEnd - trimData.trimStart,
      removeBackground: {
        isBackgroundRemoved: true,
        trimData: {
          startTime: trimData.trimStart,
          endTime: trimData.trimEnd,
        },
      },
    });
  }

  public async disableRemoveBackgroundFlag(): Promise<void> {
    await this.updateFromObject({
      startTime: this.removeBackground.trimData.startTime,
      endTime: this.removeBackground.trimData.endTime,
      removeBackground: {
        isBackgroundRemoved: false,
      },
    });
  }

  public async onItemTrimmed(): Promise<void> {
    await this.seekToPageTime();
  }
}
