import {Item} from '@PosterWhiteboard/items/item/item.class';
import {Rect} from '@postermywall/fabricjs-2';
import type {BaseItemObject, InitItemOpts} from '@PosterWhiteboard/items/item/item.types';
import {ITEM_TYPE} from '@PosterWhiteboard/items/item/item.types';
import type {FillObject} from '@PosterWhiteboard/classes/fill.class';
import type {FillTypes} from '@PosterWhiteboard/classes/fill.class';
import {Fill} from '@PosterWhiteboard/classes/fill.class';
import type {Page} from '@PosterWhiteboard/page/page.class';
import type {RGBA} from '@Utils/color.util';
import type {DeepPartial} from '@/global';
import type {
  CopyableItemStylesAndProperties,
  RectangleItemStyles,
} from '@Components/poster-editor/components/poster-editing-side-panel/components/poster-item-controls/poster-item-controls.types';
import {pasteStylesForRectangleItem} from '@PosterWhiteboard/libraries/paste-styles.library';

export interface RectangleItemObject extends BaseItemObject {
  rx: number;
  ry: number;
  fill: FillObject;
}

const DEFAULT_DIMENSION = 300;

export class RectangleItem extends Item {
  declare fabricObject: Rect;
  public rx = 0;
  public ry = 0;
  public gitype: ITEM_TYPE.RECTANGLE = ITEM_TYPE.RECTANGLE;
  public fill: Fill;

  constructor(page: Page) {
    super(page);
    this.fill = new Fill();
  }

  public override toObject(): RectangleItemObject {
    return {
      ...super.toObject(),
      fill: this.fill.toObject(),
      rx: this.rx,
      ry: this.ry,
    };
  }

  public getCopyableStyles(): CopyableItemStylesAndProperties {
    return {
      ...super.getCopyableStyles(),
      rx: this.rx,
      ry: this.ry,
      fill: this.fill.toObject(),
    } as RectangleItemStyles;
  }

  public override getColors(): RGBA[] {
    let colors: RGBA[] = super.getColors();

    if (this.fill.hasFill()) {
      colors = [...colors, ...this.fill.fillColor];
    }

    colors.push(this.border.solidBorderColor);

    return colors;
  }

  public async pasteStyles(copiedProperties: CopyableItemStylesAndProperties): Promise<void> {
    await pasteStylesForRectangleItem(copiedProperties, this);
  }

  public updateFillColor(fillColor: RGBA[], undoable = true): void {
    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  public updateFillType(newType: FillTypes): void {
    void this.updateFromObject({
      fill: {
        fillType: newType,
        fillColor: this.fill.getColorForNewType(newType),
      },
    });
  }

  public updateFillColorOpacity(fillAlpha: number, undoable = true): void {
    const fillColor = this.fill.toObject().fillColor.slice();
    for (let i = 0; i < fillColor.length; i++) {
      const color = fillColor[i].slice();
      color[3] = fillAlpha;
      fillColor[i] = color as RGBA;
    }

    void this.updateFromObject(
      {
        fill: {
          fillColor,
        },
      },
      {
        undoable,
      }
    );
  }

  protected override getFabricObjectForItem(opts?: InitItemOpts): Promise<Rect> {
    return new Promise<Rect>((resolve) => {
      const obj = new Rect({
        width: opts?.width ?? DEFAULT_DIMENSION,
        height: opts?.height ?? DEFAULT_DIMENSION,
        uniformScaling: false,
        uniformRoundness: true,
        scaleX: opts?.scaleX ?? 1,
        scaleY: opts?.scaleY ?? 1,
      });
      resolve(obj);
    });
  }

  public override copyVals(obj: DeepPartial<RectangleItemObject>): void {
    const {fill, ...itemObj} = obj;
    super.copyVals(itemObj);
    this.fill.copyVals(fill);
  }

  public override async updateFabricObject(): Promise<void> {
    await super.updateFabricObject();
    this.fabricObject.set({
      rx: this.rx,
      ry: this.ry,
      fill: this.fill.getFill(this.fabricObject.width, this.fabricObject.height),
    });
  }
}
